import React from "react";
import donateBannerIcon from "@images/common/donate-banner-icon.svg";
import Image from "@common/Image/Image";
import { BannerButtonStyles } from "./BannerButtonStyles";

export default function BannerButton({ setIsOpenedBanner }) {
    return (
        <>
            <BannerButtonStyles />
            <div className="donate-btn">
                <Image
                    src={donateBannerIcon}
                    className="donate-btn-image"
                    onClick={() => setIsOpenedBanner(true)}
                    alt="Donate button"
                />
            </div>
        </>
    );
}
